import React, { useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';

// styled components
import { StyledSuccessProject } from './StyledSuccessProject';

// react icon
import { AiOutlineClose, AiFillEye, AiFillCheckCircle } from 'react-icons/ai';
import { MdOutlineContentCopy } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';

// api
import { visitGet } from 'api/visit/visit.api';

// plugin
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

// i18n
import { useTranslation } from 'react-i18next';

// images
import file from './images/file.svg';
import could from './images/could.svg';

// constants
import SERVER from 'constants/server';

const SuccessProject = (props) => {
    const { t } = useTranslation();

    const store = useSelector(store => store);

    // const [thisMonthCount, setThisMonthCount] = useState(0);
    // const [isOpen, setOpen] = useState(props.publish);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getMode = urlParams.get('mode');

    // useEffect(() => {
    //     visitGet(Cookies.get('token')).then((res) => {
    //         setThisMonthCount(res.thisMonthCount);
    //     });
    // }, []);

    const isScankit = props.scanViewerUrl;

    const publishUrl = isScankit ? `${SERVER.ARPLATFORM}/viewer?uid=${props.uuid}` : `${SERVER.VIEWER_URL}/?uid=${props.uuid}`;

    const closePublish = (e) => {
        e.preventDefault();
        props.onCallParent(false);
        // TODO:目前mouseEvent由<scankit>傳入props，所以才需要處理；而目前scankit未使用，待未來移除的話，這邊也可以刪除
        // 會加上一個判斷，是由於<CardList>未傳入event prop，否則會報錯
        if (isScankit && props.setPreventMouseEvent) {
            props.setPreventMouseEvent(false);
            return;
        }
        if (!getMode) {
            props.setCanHoverSetting(true);
        }
    };

    const [copyStatus, setCopyStatus] = useState(false);

    const copyUrlEvent = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(publishUrl).then(() => {
            setCopyStatus(true);
            setTimeout(() => setCopyStatus(false), 2000);
        });
    };

    const stopPropagationEvent = (e) => {
        e.stopPropagation();
    };

    return (
        <StyledSuccessProject onClick={stopPropagationEvent}>
            {
                copyStatus &&
                <div className='copySuccess'>
                    <AiFillCheckCircle />
                    {t('successfullyCopied')}
                </div>
            }
            <div className="whiteBg" onClick={stopPropagationEvent}>
                <a href="#" className='closeIcon' onClick={closePublish}>
                    <AiOutlineClose />
                </a>
                <div className="title">
                    <div className="icon">
                        <AiFillCheckCircle />
                    </div>
                    {props.updateStatus ? t('isSuccessfullyUpdated') : t('isSuccessfullyPublished')}
                </div>
                <div className="copyBar">
                    <input type="text" readOnly value={publishUrl} />
                    <a href="#" onClick={copyUrlEvent} className='copy'>
                        <MdOutlineContentCopy />
                    </a>
                </div>
                <ul className='listUl'>
                    <li>
                        <div>
                            <div className='icon'>
                                <img src={file} />
                            </div>
                            {t('totalCreatedProject')}
                        </div>
                        <div>
                            {
                                store.projectRecord.projectCount
                            }
                            /
                            {
                                store.permits.projectLimit
                            }
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className='icon'>
                                <img src={could} />
                            </div>
                            {t('totalPublicProject')}
                        </div>
                        <div>
                            {
                                store.projectRecord.projectPublicCount
                            }
                            /
                            {
                                store.permits.publicProject
                            }
                        </div>
                    </li>
                    {/* <li style={{ display: 'none' }}>
                        <div>
                            <div className='icon'>
                                <AiFillEye />
                            </div>
                            {t('totalProjectViews')}
                        </div>
                        <div>
                            {
                                thisMonthCount
                            }
                            /
                            {

                                store.permits.visitPerMonth

                            }
                        </div>
                    </li> */}
                    <li className='lastLi'>
                        <div>{t('expirydate')}：
                            {dayjs(store.permits.startAt).format('YYYY/MM/DD')}
                            -
                            {dayjs(store.permits.expiredAt).format('YYYY/MM/DD')}

                        </div>
                        {/* <a href="https://ar.istaging.com/billing/" target="_blank">
                            {t('checkplan')}
                            <div className="underIcon">
                                <IoIosArrowForward />
                            </div>
                        </a> */}
                    </li>
                    <li>
                        <a href={publishUrl} className={isScankit ? 'scanViewerBtn' : 'publishBtn'} target='_blank'>
                            {t('checkviewer')}
                        </a>
                    </li>
                </ul>
            </div>
        </StyledSuccessProject>
    );
};

export default SuccessProject;