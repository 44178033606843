// image
import line from './icons/line.svg';
import file from './icons/file.svg';
import upload from './icons/upload.svg';
import pen from './icons/pen.svg';
import search from './icons/search.svg';

// Component
import ObjectCode from './ObjectCode';

// i18n
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// plugins
import clsx from 'classnames';

import { useSelector } from 'react-redux';

const AREA = 'Asia/Taipei';
dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = (date) => (date ? dayjs(date).format('YYYY/MM/DD') : '----/--/--');

const ProjectDetailPanel = ({ data, publicText, publicStatus }) => {
    const { t } = useTranslation();
    const store = useSelector(store => store);

    const createTime = useMemo(() => formatDate(data?.project.dateCreated)
        , [data]);
    const lastUpdate = useMemo(() => formatDate(data?.project.lastUpdated)
        , [data]);
    const lastPublic = useMemo(() => formatDate(data?.project.editors[0].lastPublic)
        , [data]);

    const isObjectCodeFeature = useMemo(() => {
        const now = dayjs().tz(AREA);
        const features = store?.userInfo?.ssoData?.permission?.features;
        if (!features) {
            console.error('There has some error when fetching features from userInfo at isObjectCodeFeature()');
            return false;
        }
        const objectCodeFeature = features.find((feature) => feature.name.toLowerCase() === 'objectCode'.toLowerCase());
        if (objectCodeFeature) {
            const objectCodeExpiredAt = dayjs.tz(objectCodeFeature?.expiredAt, AREA);
            return now.isBefore(objectCodeExpiredAt);
        }
        return false;
    }, [store?.userInfo?.ssoData?.permission?.features]);

    const panelConfig = [
        {
            iconSrc: line,
            title: t('projectType'),
            content: <div className="detailType">AR Object</div>,
        },
        {
            iconSrc: line,
            title: t('projectStatus'),
            content: (
                <div className={clsx('detailContent', 'draftText', { 'publicTrue': publicStatus })}>
                    {publicText}
                </div>
            ),
        },
        { iconSrc: search, title: t('objectCode'), content: <ObjectCode /> },
        {
            iconSrc: file,
            title: t('createTime'),
            content: <div className="detailContent createdDate">{createTime}</div>,
        },
        {
            iconSrc: upload,
            title: t('latestPublicTime'),
            content: (
                <div className={clsx('detailContent', 'lastPublish', { 'nullDate': lastPublic === '----/--/--' })}>
                    {lastPublic}
                </div>
            ),
        },
        {
            iconSrc: pen,
            title: t('lastEditTime'),
            content: (
                <div className={clsx('detailContent', 'lastEdit', { 'nullDate': !lastUpdate })}>
                    {lastUpdate}
                </div>
            ),
        },
    ];
    return (
        <div className="projectDetailPanel">
            {panelConfig.map(({ iconSrc, title, content }, index) => {
                if (title === t('objectCode') && !isObjectCodeFeature) { return null; }

                return (
                    <div key={index} className="detailPanel flexStartCenter">
                        <img src={iconSrc} alt="" className="detailIcon" />
                        <div className="detailText">
                            {title}
                        </div>
                        {content}
                    </div>);
            })}
        </div>
    );
};

export default ProjectDetailPanel;